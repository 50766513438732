import { FC } from 'react'

import Typography from '../typography/Typography'
import './FormItemDivider.less'

const { Text } = Typography
type Props = {
  label?: string
  subTitle?: string
  marginTop?: string | number
  marginBottom?: string | number
}
const FormItemDivider: FC<Props> = ({ label, marginBottom = 10, marginTop = 8, subTitle }) => {
  return (
    <div
      className={'form-sub-title-divider'}
      style={{
        marginTop: Number(marginTop),
        marginBottom: Number(marginBottom),
      }}
    >
      <div className={'label-wrapper'}>
        {label && (
          <Text weight={'w600'} size={'md'}>
            {label}
          </Text>
        )}
      </div>

      {subTitle && (
        <Text weight={'w400'} size={'sm'} className={'sub-title'} color={'light-gray'}>
          {subTitle}
        </Text>
      )}
    </div>
  )
}

export default FormItemDivider
