import moment from 'moment'

const d = new Date()
const monthNames = ['Jan’', 'Feb’', 'Mar’', 'Apr’', 'May’', 'June', 'July', 'Aug’', 'Sept’', 'Oct’', 'Nov’', 'Dec’']
export const date = `${monthNames[d.getMonth()]} ${d.getUTCDate()}`

export const getCardDate = (date: Date): string => {
  const d = new Date(date)
  return `${monthNames[d.getMonth()]} ${d.getUTCDate()}, ${d.getFullYear()}`
}
export const formatNumber = (value: number): string => new Intl.NumberFormat().format(value)

export const generateApplicationName = (clientCompanyName: Maybe<string>, totalAppNumber: Maybe<number>, index: number): string => {
  const companyNamePrefix = clientCompanyName ? clientCompanyName?.slice(0, 3)?.toUpperCase() : 'APP-'
  const currentYear = new Date().getFullYear().toString().slice(-2)
  const applicationNumber = ((index + 1) + (totalAppNumber || 0)).toString().padStart(4, '0')

  return `${companyNamePrefix}${currentYear}-${applicationNumber}`
}

type IdArray = (number | '' | null | undefined)[] | undefined
export const arraysCheck = (arr1: IdArray, arr2: IdArray): boolean => {
  const isMach: (boolean | undefined)[] = []
  arr1 &&
  arr1.forEach(it => {
    isMach.push(arr2 && arr2.includes(it))
  })
  return isMach.includes(true)
}

export type GetDateTimeReturn = {date: string | null, time: string |null}
export const getDateAndTime = (date: Date | null | undefined): GetDateTimeReturn  => {
  if (date) {
    const momentDate = moment(date).format('DD MMM YYYY')
    const momentTime = moment(date).format('h:mm A')

    return {
      date: momentDate,
      time: momentTime,
    }
  } else {
    return {
      date: null,
      time: null,
    }
  }
}

export function getBase64(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string | null)
    reader.onerror = error => reject(error)
  })
}

export function getUrlExtension(url: string): string | null {
  if (url && url.length) {
    const splited = url.split(/[#?]/)[0].split('.')
    if (splited) {
      const res = splited?.pop()
      if (res) {
        return res.trim().toLowerCase()
      }
    }
    // return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase()
  }
  return null
}

export function checkIsReadyForReview(app: ApplicationEntity, slotsAmount: number): boolean {
  const payment = app?.attributes?.payments?.data?.find(
    (it: PaymentEntity) => it?.attributes?.type === 'invoice',
  ) as PaymentEntity
  if (payment?.attributes?.status === 'paid') {
    const uploadInfo = app?.attributes?.requiredDocuments as Maybe<ComponentDataRequiredDocuments>[]
    const uploadInfoSlots = Object.entries(uploadInfo)?.length
    if (uploadInfoSlots === slotsAmount) {
      const checkList = []
      for (const [key, value] of Object.entries(uploadInfo)) {
        if (value) {
          checkList.push(true)
        }
      }
      return !checkList.includes(false)
    } else {
      return false
    }
  } else {
    return false
  }
}

export function downloadFile(url: string): void {
  const a = document.createElement('a')
  a.href = url
  a.download = url.split('/').pop() as string
  a.target = 'blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0)?.toUpperCase() + string?.slice(1)
}

export default date
